import React, { useState, useCallback, useEffect } from 'react'
import { updateReport, getReportById } from 'utils/api'
import { Report, ArrivalReportData, ArrivalFormProps } from 'types'

export const ArrivalReportForm: React.FC<ArrivalFormProps> = ({
  reportId,
  onReportUpdated,
}) => {
  const [report, setReport] = useState<Report | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [arrivalOdo, setArrivalOdo] = useState<number>(0)
  const [arrivalTime, setArrivalTime] = useState<string>('')
  const [isGarbageOrAbnormal, setIsGarbageOrAbnormal] = useState<boolean>(false)
  const [abnormal, setAbnormal] = useState<boolean>(false)
  const [gasMeter, setGasMeter] = useState<number>(0)

  useEffect(() => {
    const fetchReport = async () => {
      setLoading(true)
      setError(null)
      try {
        const fetchedReport = await getReportById(reportId)
        setReport(fetchedReport)
        // 明示的に number 型としてキャストし、undefined の場合はデフォルト値 0 を使用
        setArrivalOdo((fetchedReport.departureOdo as number | undefined) ?? 0)
        setLoading(false)
      } catch (error) {
        console.error('Failed to fetch report:', error)
        setError('報告の読み込みに失敗しました。')
        setLoading(false)
      }
    }
    void fetchReport()
  }, [reportId])

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      if (!report) return

      try {
        const updateData: ArrivalReportData = {
          arrivalOdo: arrivalOdo,
          arrivalTime: arrivalTime,
          isAbnormal: abnormal,
          gasMeter: gasMeter,
          driverId: 0,
          passengers: [],
        }
        await updateReport(reportId, updateData)
        onReportUpdated()
      } catch (error) {
        console.error('Failed to update report:', error)
        setError('報告の更新に失敗しました。')
      }
    },
    [
      reportId,
      arrivalOdo,
      arrivalTime,
      abnormal,
      gasMeter,
      report,
      onReportUpdated,
    ],
  )

  if (loading) return <div>読み込み中...</div>
  if (error) return <div>エラー: {error}</div>
  if (!report) return <div>報告が見つかりません。</div>

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        void handleSubmit(e)
      }}
    >
      <h2>到着報告</h2>
      <div>
        <label htmlFor="arrival_time">到着時刻:</label>
        <input
          type="datetime-local"
          id="arrival_time"
          value={arrivalTime}
          onChange={(e) => setArrivalTime(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="arrival_odo">到着時ODO:</label>
        <input
          type="number"
          id="arrival_odo"
          value={arrivalOdo}
          onChange={(e) => setArrivalOdo(Number(e.target.value))}
          required
        />
      </div>
      <div>
        <label htmlFor="is_garbage_or_abnormal">ゴミ・異常の有無:</label>
        <input
          type="checkbox"
          id="is_garbage_or_abnormal"
          checked={isGarbageOrAbnormal}
          onChange={(e) => setIsGarbageOrAbnormal(e.target.checked)}
        />
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={abnormal}
            onChange={(e) => setAbnormal(e.target.checked)}
          />
          異常あり
        </label>
      </div>
      <div>
        <label htmlFor="gas_meter">ガソリン残量:</label>
        <input
          type="number"
          id="gas_meter"
          value={gasMeter}
          onChange={(e) => setGasMeter(Number(e.target.value))}
          required
        />
      </div>
      <button type="submit">到着報告を提出</button>
    </form>
  )
}
