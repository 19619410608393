import React, { useState, useCallback, useEffect } from 'react'
import { DepartureReportData, ReportFormProps } from 'types'

export const DepartureReportForm: React.FC<ReportFormProps> = ({
  cars,
  users,
  selectedCar,
  onSubmit,
}) => {
  // formDataの状態管理
  const [formData, setFormData] = useState<DepartureReportData>(() => ({
    carId: selectedCar?.id || 0,
    driverId: 0,
    passengers: [],
    purpose: '',
    departureOdo: selectedCar?.current_odo || 0,
    hasGarbage: false,
    departureGasMeter: 0,
  }))

  // selectedCarが変更されたときにformDataを更新
  useEffect(() => {
    if (selectedCar) {
      setFormData((prev) => ({
        ...prev,
        carId: selectedCar.id,
        departureOdo: selectedCar.current_odo,
      }))
    }
  }, [selectedCar])

  const [isDepartureOdoEditable, setIsDepartureOdoEditable] = useState(false)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const { name, value, type } = e.target
      setFormData((prev) => ({
        ...prev,
        [name]:
          type === 'number' || name === 'carId' || name === 'driverId'
            ? parseInt(value, 10)
            : value,
      }))
    },
    [],
  )

  const handlePassengerChange = useCallback((index: number, value: string) => {
    setFormData((prev) => {
      const newPassengers = [...prev.passengers]
      newPassengers[index] = parseInt(value, 10)
      return { ...prev, passengers: newPassengers }
    })
  }, [])

  const handleAddPassenger = useCallback(() => {
    setFormData((prev) => ({ ...prev, passengers: [...prev.passengers, 0] }))
  }, [])

  const handleRemovePassenger = useCallback((index: number) => {
    setFormData((prev) => {
      const newPassengers = [...prev.passengers]
      newPassengers.splice(index, 1)
      return { ...prev, passengers: newPassengers }
    })
  }, [])

  const handleDepartureOdoClick = useCallback(() => {
    if (!isDepartureOdoEditable) {
      const confirmEdit = window.confirm('出発時ODOを編集しますか？')
      if (confirmEdit) {
        setIsDepartureOdoEditable(true)
      }
    }
  }, [isDepartureOdoEditable])

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      onSubmit(formData, true)
    },
    [formData, onSubmit],
  )

  const isUserSelected = useCallback(
    (userId: number): boolean => {
      return (
        formData.driverId === userId || formData.passengers.includes(userId)
      )
    },
    [formData.driverId, formData.passengers],
  )

  return (
    <form onSubmit={handleSubmit}>
      <h2>出発報告</h2>
      <div>
        <label htmlFor="carId">車両:</label>
        <select
          id="carId"
          name="carId"
          value={selectedCar?.id || ''}
          onChange={handleChange}
          disabled={selectedCar !== null}
          required
        >
          <option value="">選択してください</option>
          {cars?.map((car) => (
            <option
              key={car.id}
              value={car.id}
              disabled={car.status === 'in_use'}
            >
              {car.car_number} - {car.model}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="driverId">運転手:</label>
        <select
          id="driverId"
          name="driverId"
          value={formData.driverId}
          onChange={handleChange}
          required
        >
          <option value="">選択してください</option>
          {users?.map((user) => (
            <option
              key={user.id}
              value={user.id}
              disabled={
                isUserSelected(user.id) && formData.driverId !== user.id
              }
            >
              {user.email}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>同乗者:</label>
        {formData.passengers.map((passengerId, index) => (
          <div key={index}>
            <select
              value={passengerId}
              onChange={(e) => handlePassengerChange(index, e.target.value)}
            >
              <option value="">選択してください</option>
              {users?.map((user) => (
                <option
                  key={user.id}
                  value={user.id}
                  disabled={isUserSelected(user.id) && passengerId !== user.id}
                >
                  {user.email}
                </option>
              ))}
            </select>
            <button type="button" onClick={() => handleRemovePassenger(index)}>
              削除
            </button>
          </div>
        ))}
        {selectedCar &&
          formData.passengers.length < selectedCar.capacity - 1 && (
            <button type="button" onClick={handleAddPassenger}>
              同乗者を追加
            </button>
          )}
      </div>

      <div>
        <label htmlFor="departureOdo">出発時ODO:</label>
        <input
          type="number"
          id="departureOdo"
          name="departureOdo"
          value={formData.departureOdo}
          onChange={handleChange}
          onClick={handleDepartureOdoClick}
          readOnly={!isDepartureOdoEditable}
          required
        />
      </div>
      <div>
        <label htmlFor="purpose">目的:</label>
        <select
          id="purpose"
          name="purpose"
          value={formData.purpose}
          onChange={handleChange}
          required
        >
          <option value="">選択してください</option>
          <option value="通学">通学</option>
          <option value="私用">私用</option>
          <option value="給油">給油</option>
          <option value="その他">その他</option>
        </select>
      </div>
      <button type="submit">出発報告書を提出</button>
    </form>
  )
}
