// ReportForm.tsx
import React, { useState } from 'react'
import { DepartureReportForm } from './DepartureReportForm'
import { ArrivalReportForm } from './ArrivalReportForm'
import type { ReportFormProps } from '../types'

export const ReportForm: React.FC<ReportFormProps> = ({
  cars,
  users,
  selectedCar,
  activeReportId,
  onSubmit,
  onReportCreated,
  onReportUpdated,
}) => {
  const [isDepature, setIsDepature] = useState(
    selectedCar ? selectedCar.status !== 'in_use' : true,
  )

  if (!selectedCar) {
    return null
  }

  return (
    <div className="report-form">
      <div className="tab-buttons">
        <button
          type="button"
          className={isDepature ? 'active' : ''}
          onClick={() => setIsDepature(true)}
          disabled={selectedCar.status === 'in_use'}
        >
          出発報告
        </button>
        <button
          type="button"
          className={!isDepature ? 'active' : ''}
          onClick={() => setIsDepature(false)}
          disabled={selectedCar.status !== 'in_use'}
        >
          帰着報告
        </button>
      </div>

      {isDepature ? (
        <DepartureReportForm
          cars={cars}
          users={users}
          selectedCar={selectedCar}
          activeReportId={activeReportId}
          onSubmit={onSubmit}
          onReportCreated={onReportCreated}
          onReportUpdated={onReportUpdated}
        />
      ) : activeReportId ? (
        <ArrivalReportForm
          cars={cars}
          users={users}
          selectedCar={selectedCar}
          activeReportId={activeReportId}
          reportId={activeReportId}
          onSubmit={onSubmit}
          onReportCreated={onReportCreated}
          onReportUpdated={onReportUpdated}
        />
      ) : null}
    </div>
  )
}
