import { http, HttpResponse } from 'msw'
import { Car, CarStatus } from '../types/car'
import { Report, DepartureReportData, ArrivalReportData } from '../types/report'
import { User } from '../types/user'

// モックデータ
let cars: Car[] = [
  {
    id: 1,
    car_number: '函館500あ1234',
    car_color: 'white',
    model: 'Suzuki Alto',
    capacity: 4,
    status: 'available',
    current_odo: 0,
  },
  {
    id: 2,
    car_number: '函館500あ5678',
    car_color: 'silver',
    model: 'Suzuki Alto',
    capacity: 4,
    status: 'available',
    current_odo: 0,
  },
]

const reports: Report[] = []
let reportIdCounter = 1

const users: User[] = [
  { id: 1, roomId: 101, email: 'user1@example.com' },
  { id: 2, roomId: 102, email: 'user2@example.com' },
  { id: 3, roomId: 103, email: 'user3@example.com' },
]

export const handlers = [
  // 車両一覧の取得
  http.get('/cars', () => {
    return HttpResponse.json(cars)
  }),

  // ユーザー一覧の取得
  http.get('/users', () => {
    return HttpResponse.json(users.map(({ id, email }) => ({ id, email })))
  }),

  // 出発報告書の作成
  http.post<never, DepartureReportData>('/api/reports', async ({ request }) => {
    const reportData = await request.json()

    if (!reportData.carId || !reportData.driverId || !reportData.purpose) {
      return HttpResponse.json(
        { error: '必須フィールドが不足しています' },
        { status: 400 },
      )
    }

    const car = cars.find((c) => c.id === reportData.carId)
    if (!car) {
      return HttpResponse.json(
        { error: '指定された車両が見つかりません' },
        { status: 404 },
      )
    }

    const newReport: Report = {
      id: reportIdCounter++,
      carId: reportData.carId,
      carNumber: car.car_number,
      model: car.model,
      driverId: reportData.driverId,
      passengers: reportData.passengers || [],
      purpose: reportData.purpose,
      departureTime: new Date().toISOString(),
      departureOdo: reportData.departureOdo,
      arrivalTime: null,
      arrivalOdo: null,
      isAbnormal: null,
      gasMeter: null,
      hasGarbage: reportData.hasGarbage,
      departureGasMeter: reportData.departureGasMeter,
    }

    reports.push(newReport)

    // 車両のステータスを更新
    cars = cars.map((car: Car) =>
      car.id === reportData.carId
        ? { ...car, status: 'in_use' as CarStatus }
        : car,
    )

    return HttpResponse.json({
      message: '出発報告書が作成されました',
      reportId: newReport.id,
    })
  }),

  // 到着報告の更新
  http.put<{ reportId: string }, ArrivalReportData>(
    '/api/reports/:reportId',
    async ({ request, params }) => {
      const reportId = parseInt(params.reportId, 10)
      const updateData = await request.json()

      const reportIndex = reports.findIndex((report) => report.id === reportId)
      if (reportIndex === -1) {
        return HttpResponse.json(
          { error: '報告が見つかりません' },
          { status: 404 },
        )
      }

      reports[reportIndex] = {
        ...reports[reportIndex],
        arrivalTime: updateData.arrivalTime,
        arrivalOdo: updateData.arrivalOdo,
        isAbnormal: updateData.isAbnormal,
        gasMeter: updateData.gasMeter,
      }

      // 車両のステータスを更新
      const report = reports[reportIndex]
      cars = cars.map((car: Car) =>
        car.id === report.carId
          ? { ...car, status: 'available' as CarStatus }
          : car,
      )

      return HttpResponse.json({ message: '到着報告が更新されました' })
    },
  ),

  // 車両状態の取得
  http.get('/api/car-status', () => {
    const carStatuses: Record<number, CarStatus> = {}
    cars.forEach((car) => {
      carStatuses[car.id] = car.status
    })
    return HttpResponse.json(carStatuses)
  }),

  // アクティブな報告書の取得
  http.get('/api/active-reports', () => {
    const activeReports = reports.filter((report) => !report.arrivalTime)
    return HttpResponse.json(activeReports)
  }),

  // 特定の報告書の取得
  http.get('/api/reports/:id', ({ params }) => {
    const reportId = parseInt(params.id as string, 10)
    const report = reports.find((r) => r.id === reportId)

    if (report) {
      return HttpResponse.json(report)
    }
    return new HttpResponse(null, { status: 404 })
  }),
]
