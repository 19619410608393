import { client } from 'utils/config'

// ログイン処理
export interface LoginResponse {
  user: {
    id: number
    email: string
  }
}

export const login = async (
  email: string,
  password: string,
): Promise<LoginResponse> => {
  const response = await client.post<LoginResponse>('/api/login', {
    email,
    password,
  })
  return response.data
}

// ログアウト処理
export const logout = async () => {
  await client.post('/api/logout')
  localStorage.removeItem('token')
}

// 認証状態を確認する処理
export const isAuthenticated = () => {
  // 認証情報があるかどうかを確認する処理を追加
  // 例えば、localStorageにトークンがあるかどうかを確認する
  const token = localStorage.getItem('token')
  return !!token
}
