import axios from 'axios'
import {
  Car,
  Report,
  DepartureReportData,
  ArrivalReportData,
  User,
  CarStatus,
} from 'types'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:1323'

export const fetchCars = async (): Promise<Car[]> => {
  const response = await axios.get<Car[]>(`${API_BASE_URL}/cars`)
  return response.data
}

export const fetchUsers = async (): Promise<User[]> => {
  const response = await axios.get<User[]>(`${API_BASE_URL}/users`)
  return response.data
}
export const getLatestArrivalOdo = async (carId: number): Promise<number> => {
  const response = await axios.get<Car>(`${API_BASE_URL}/cars/${carId}`)
  return response.data.current_odo
}

export const createReport = async (
  reportData: DepartureReportData,
): Promise<Report> => {
  const response = await axios.post<Report>(
    `${API_BASE_URL}/reports`,
    reportData,
  )
  return response.data
}

export const updateReport = async (
  reportId: number,
  updateData: ArrivalReportData,
): Promise<Report> => {
  const payload = {
    arrival_odo: updateData.arrivalOdo,
    arrival_time: updateData.arrivalTime,
    is_abnormal: updateData.isAbnormal,
    gas_meter: updateData.gasMeter,
  }

  const response = await axios.put<Report>(
    `${API_BASE_URL}/reports/${reportId}`,
    payload,
  )
  return response.data
}

// タイムアウト設定付きのaxiosインスタンス
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000, // 5秒でタイムアウト
})

export const getCarStatus = async (
  signal?: AbortSignal,
): Promise<Record<number, CarStatus>> => {
  try {
    const response = await apiClient.get<Record<number, CarStatus>>(
      '/car-status',
      {
        signal: signal, // AbortControllerのsignalを渡す
      },
    )
    return response.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // キャンセルされた場合は専用のエラーをスロー
      throw new Error('Request was cancelled')
    }
    throw error
  }
}

export const fetchActiveReports = async (): Promise<Report[]> => {
  const response = await axios.get<Report[]>(
    `${API_BASE_URL}/reports?active=true`,
  )
  return response.data
}

export const getReportById = async (reportId: number): Promise<Report> => {
  const response = await axios.get<Report>(
    `${API_BASE_URL}/reports/${reportId}`,
  )
  return response.data
}
