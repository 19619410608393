import React, { useState, useEffect, useCallback } from 'react'
import {
  Car,
  Report,
  DepartureReportData,
  ArrivalReportData,
  User,
} from 'types'
import {
  fetchCars,
  fetchActiveReports,
  createReport,
  updateReport,
  fetchUsers,
} from 'utils/api'
import { ReportForm } from 'components/ReportForm'
import 'styles/CarManagement.scss'

interface CarCardProps {
  car: Car
  onClick: (car: Car) => void
}

const CarCard: React.FC<CarCardProps> = ({ car, onClick }) => (
  <div
    className={`car-card ${car.status === 'in_use' ? 'in-use' : ''}`}
    onClick={() => onClick(car)}
  >
    <h3>
      {car.car_number} - {car.model}
    </h3>
    <p>定員: {car.capacity}人</p>
    <p>車両色: {car.car_color}</p>
    <p>状態: {car.status}</p>
  </div>
)

export const CarManagement: React.FC = () => {
  const [cars, setCars] = useState<Car[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [activeReports, setActiveReports] = useState<Report[]>([])
  const [selectedCar, setSelectedCar] = useState<Car | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true)
      const [fetchedCars, fetchedReports, fetchedUsers] = await Promise.all([
        fetchCars(),
        fetchActiveReports(),
        fetchUsers(),
      ])
      setCars(fetchedCars || [])
      setActiveReports(fetchedReports || [])
      setUsers(fetchedUsers || [])
      setError(null)
    } catch (error) {
      console.error('Failed to load data:', error)
      setError('データの読み込みに失敗しました。')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    void loadData()
  }, [loadData])

  const handleReportSuccess = useCallback(() => {
    void loadData()
    setSelectedCar(null)
  }, [loadData])

  const handleSubmit = useCallback(
    (data: DepartureReportData | ArrivalReportData, isDepature: boolean) => {
      void (async () => {
        try {
          if (isDepature) {
            await createReport(data as DepartureReportData)
          } else if (selectedCar) {
            const activeReport = activeReports.find(
              (report) => report.carId === selectedCar.id,
            )
            if (activeReport) {
              await updateReport(activeReport.id, data as ArrivalReportData)
            }
          }
          await loadData()
          setSelectedCar(null)
        } catch (error) {
          console.error('Failed to submit report:', error)
          setError('報告の送信に失敗しました。')
        }
      })()
    },
    [activeReports, loadData, selectedCar],
  )

  const handleCarSelect = useCallback((car: Car) => {
    setSelectedCar(car)
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="car-management">
      <h1>車両管理システム</h1>

      {error && <div className="error-message">{error}</div>}

      <h2>車両一覧</h2>
      <div className="car-grid">
        {cars.length > 0 ? (
          cars.map((car: Car) => (
            <CarCard key={car.id} car={car} onClick={handleCarSelect} />
          ))
        ) : (
          <p>登録されている車両がありません。</p>
        )}
      </div>

      <ReportForm
        cars={cars}
        users={users}
        selectedCar={selectedCar}
        activeReportId={
          selectedCar
            ? (activeReports.find((report) => report.carId === selectedCar.id)
                ?.id ?? null)
            : null
        }
        onSubmit={handleSubmit}
        onReportCreated={handleReportSuccess}
        onReportUpdated={handleReportSuccess}
      />

      <h2>出発済みの車両</h2>
      <ul className="active-reports">
        {activeReports.length > 0 ? (
          activeReports.map((report) => (
            <li key={report.id}>
              {report.carNumber} - {report.model} - 出発時刻:{' '}
              {new Date(report.departureTime).toLocaleString()}
            </li>
          ))
        ) : (
          <li>現在出発中の車両はありません</li>
        )}
      </ul>
    </div>
  )
}
